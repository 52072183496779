.book-component-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.book-component-container > .book {
  position: absolute;
  display: flex;
  width: 441px;
  height: 315px;
  transition: all 0.5s linear;
  right: 0;
  bottom: 0;
  left: initial;
  top: initial;
  z-index: 2;
}

.book-component-container > .book.open {
  margin: auto;
  right: 50px;
  bottom: -150px;
  left: 0;
  top: 0;
}

.book-component-container > .book.open > .pages > .page.open {
  transform: rotateY(180deg);
}

.book-component-container > .book.closed {
  right: 50px;
  bottom: -150px;
}

.book-component-container > .book > .back {
  flex: 1 1 50%;
  height: 100%;
}

.book-component-container > .book > .pages {
  flex: 1 1 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.book-component-container > .book > .pages > .page {
  list-style-type: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid black;
  transition-delay: 0.5s;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
  transform-origin: left;
  position: absolute;
  width: 95%;
  height: 95%;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 0px 24px 24px 0px;
}

.book-component-container > .book > .pages > .page > li {
  flex: 1 1;
  border-bottom: 1px solid;
}

.book-component-container > .book > .pages > .page.cover {
  background: #0e6a48;
}

.book-component-container > .book > .pages > .page.cover > .container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}

.book-component-container > .book > .pages > .page.cover > .container > img {
  backface-visibility: hidden;
}

.book-component-container
  > .book
  > .pages
  > .page.cover
  > .container
  > img#line {
  position: absolute;
  top: 0;
  right: 40px;
}

.book-component-container
  > .book
  > .pages
  > .page.cover
  > .container
  > img:nth-child(3) {
  flex: 1 1 100%;
}

.book-component-container > .book > .pages > .page.back {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-component-container > .book > .pages > .page.back,
.book-component-container > .book > .pages > .page.cover {
  width: 100%;
  height: 100%;
  border-radius: 6px 24px 24px 6px;
}
